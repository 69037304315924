import { RequisitionError } from 'models/RequisitionError'
import {
	UploadModal,
	UploadedFilesProps
} from 'module/orderManagement/components/UploadModal'
import ExecautoErrorSpan from 'module/orderManagement/components/execautoErrorSpan'
import { OrderDetailsContextElement } from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import {
	AddNewFollowUpProps,
	PrpOrder
} from 'module/orderManagement/models/PrpOrderModel'
import { CommentaryTypes } from 'module/orderManagement/utils/constants'
import React, { useContext, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import LoadingProgress from 'shared/components/LoadingProgress'
import Select from 'shared/components/Select'
import AttachmentIcon from 'shared/components/SvgIcons/AttachmentIcon'
import { store } from 'store'
import './styles.scss'

type Props = {
	prpOrder: PrpOrder
	onClose: () => void
}

const NewFollowUp = ({ prpOrder, onClose }: Props) => {
	const { onAddCommentary, onUpdateOrderPrp, orderPrpIsLoading } = useContext(
		OrderDetailsContextElement
	)
	const [errorMessage, setErrorMessage] = useState<string>('')
	const [showUploadModal, setShowUploadModal] = useState<boolean>(false)
	const { profile } = store.getState().user

	const {
		register,
		getValues,
		control,
		handleSubmit,
		setValue,
		clearErrors,
		formState: { errors }
	} = useForm<AddNewFollowUpProps>({
		defaultValues: {
			createdAt: new Date(),
			followUpAt: null,
			orderId: prpOrder.id,
			userId: profile
		}
	})

	const { fields, replace } = useFieldArray({
		control,
		name: 'attachments'
	})

	const onCompletedCreate = (hasError?: RequisitionError) => {
		onUpdateOrderPrp(prpOrder.protheusOrder)
		if (hasError) {
			setErrorMessage(hasError.message)
			toast.error(hasError?.message || 'Erro ao adicionar comentário.')
		} else {
			onClose()
			toast.success(`Comentário adicionado com sucesso!`)
		}
	}

	const handleCreateFollowUp = async (data: AddNewFollowUpProps) => {
		data.followUpAt = data?.followUpAt ? new Date(data?.followUpAt) : null
		onAddCommentary(data, onCompletedCreate)
	}

	const onUploadFiles = (files: UploadedFilesProps[]) => {
		setShowUploadModal(false)
		const newFiles = files.map((file) => {
			return {
				description: file.name,
				orderEventId: prpOrder.id,
				role: 'string',
				file: file,
				deletedAt: null,
				attachmentCategory: file.attachmentCategory
			}
		})
		replace(newFiles)
	}

	return (
		<>
			<div className="new-follow-up">
				<div className="new-follow-up-form">
					<div className="new-follow-up-form-input">
						<header>Follow up</header>
						<Input
							name="followUpAt"
							type="date"
							errors={errors.followUpAt}
							variant="light"
							innerRef={{
								...register('followUpAt')
							}}
						/>
					</div>
					<div className="new-follow-up-form-input">
						<header>Tipo de comentário</header>
						<Select
							id="type"
							options={CommentaryTypes}
							errors={errors.type}
							selectedOption={[getValues('type')]}
							innerRef={{
								...register('type', {
									required: {
										value: true,
										message: 'Selecione um tipo de comentário'
									}
								})
							}}
							onSelectOptions={(option) => {
								setValue('type', option[0])
								clearErrors('type')
							}}
						/>
					</div>
				</div>
				<div className="new-follow-up-form-textarea">
					<textarea
						id="content"
						placeholder="Digite seu comentário aqui, mas lembre-se: comentários públicos também serão visíveis para o cliente"
						{...register('content', {
							required: {
								value: true,
								message: 'Escreva seu comentário'
							}
						})}
					/>
					<div className="span-error-container">
						{errors.content && (
							<span className="input-error-span">{errors.content.message}</span>
						)}
					</div>
				</div>
				<div className="new-follow-up-form-upload">
					<span
						className="new-follow-up-upload-action"
						onClick={() => setShowUploadModal(true)}
					>
						<AttachmentIcon height={'18'} width={'24'} />
						Anexar arquivo
					</span>
					{showUploadModal && (
						<UploadModal
							title="Anexos"
							onUploadFiles={onUploadFiles}
							files={fields.map((file) => file.file)}
							onClose={() => setShowUploadModal(false)}
							activeCategory
						/>
					)}
				</div>
				{!!errorMessage && (
					<div className="new-follow-up-integrate-error">
						<ExecautoErrorSpan message={errorMessage} />
					</div>
				)}
			</div>
			<div className="new-follow-up-actions">
				<Button
					size="lg"
					title="ENVIAR"
					variant="confirmation-gradient"
					onClick={handleSubmit(handleCreateFollowUp)}
					disabled={orderPrpIsLoading || !!errorMessage}
				/>
				<Button
					size="lg"
					title="CANCELAR"
					onClick={onClose}
					disabled={orderPrpIsLoading}
					variant="cancellation-gradient"
				/>
			</div>
			{orderPrpIsLoading && <LoadingProgress show />}
		</>
	)
}

export default NewFollowUp
